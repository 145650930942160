import type {NextPage} from 'next';
import React from 'react';
import Layout from '@common/layout';
import {wrapper} from '@redux/index';
import {END} from 'redux-saga';
import {setApp} from '@redux/app/actions';
import {EPage, TLang} from '@common/types';
import {Home} from '@modules/Home';
import {getContacts} from '@redux/contacts/actions';
import {getHome} from '@redux/home/actions';
import {useTypedSelector} from '@common/hooks/useTypedSelector';
import {GetStaticProps} from 'next';


const HomePage: NextPage = () => {
  const {page} = useTypedSelector(state => state.home)

  return (
    <Layout
      title={page?.meta?.title}
      description={page?.meta?.description}
      classNames={['main-page']}
    >
      <Home />
    </Layout>
  )
}

export const getStaticProps: GetStaticProps = wrapper.getStaticProps(store => async ({...etc}) => {
  const locale = etc.locale as TLang
  store.dispatch(getHome({ locale }))
  store.dispatch(getContacts({ locale }))
  store.dispatch(setApp({ page: EPage.HOME, draftMode: etc.draftMode ?? false, locale }))
  store.dispatch(END)
  await store?.sagaTask.toPromise()
  
  return {
    props: {}
  }
})

export default HomePage
