import React, {FC, memo} from 'react';
import {THomeCount} from '@modules/Home/home.types';
import {IHomeProduct} from '@redux/types';
import Grid from '@components/component/Grid';
import RichTextRender from '@components/component/RichTextRender';

const HomeProducts: FC<THomeCount<IHomeProduct>> = ({ count, step, products }) => {
  return (
    <section className="products" id="main-products">
      <div className="container">
        <div className="section-heading"><span className="section-heading__count">{`0${count}`}</span>
          <h2 className="section-heading__title">{step.name}</h2>
        </div>
        <div className="products__content">
          <div className="products__aside aside">
            <div className="aside__text">
              <RichTextRender content={step.note} />
            </div>
          </div>
          <div className="products__text">
            {step.title}
          </div>
        </div>
        <Grid items={products} />
      </div>
    </section>
  )
}

export default memo(HomeProducts)
