import React, {FC, memo} from 'react';
import Link from 'next/link';
import {EComponent, IHomeBanner} from '@redux/types';
import Picture from '@components/component/Picture';

const HomeMediaLinks: FC<{banner: IHomeBanner}> = ({ banner }) => {
  return (
    <section className="inner-grid inner-grid--no-padding">
      <div className="container">
        <h2 className="visually-hidden">Ссылки на соцсети</h2>
        <ul className="media-links mb-150 mb-sm-100">
          <li className="media-links__item media-links__item--big-right">
            <article className="media-links__wrapper">
              <div className="media-links__picture">
                {banner.image && (
                  <Picture
                    media={{ __component: EComponent.MEDIA, image: banner.image, imageMobile: banner.imageMobile }}
                    withoutWrapper={true}
                  />
                )}
              </div>
              <h3 className="media-links__title">{banner.title}</h3>
              {banner.button && (
                <Link className="media-links__link" href={banner.button.url}>{banner.button.title}</Link>
              )}
            </article>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default memo(HomeMediaLinks)
