import React, {FC, memo} from 'react';
import ym from 'react-yandex-metrika';
import {useTranslation} from '@common/hooks/useTranslation';
import {IMainContactsProps, ITranslation} from '@common/types';
import {useActions} from '@common/hooks/useActions';
import Link from 'next/link';
import {useLocale} from '@common/hooks/useLocale';
import {useTypedSelector} from '@common/hooks/useTypedSelector';

const MainContacts: FC<IMainContactsProps> = ({withPadding = true, count}) => {
  const actions = useActions()
  const locale = useLocale()
  const {contacts} = useTypedSelector(state => state.contacts)
  const t = useTranslation('mainContact') as ITranslation['mainContact']

  const sendYm = (type: string, secondType?: string) => {
    ym('reachGoal', type)
    if (secondType) {
      ym('reachGoal', secondType)
    }
  }

  const openForm = () => {
    actions.setApp({requestForm: {open: true}})
  }

  return (
    <section className={`main-contacts ${!withPadding ? 'no-padding' : ''}`}>
      <div className="container" style={{padding: !withPadding ? '0' : 'auto'}} data-sticky-buttons-end="">
        <h2 className="visually-hidden">{t.title}</h2>
        {count && (
          <div className="section-heading"><span className="section-heading__count">{`${count < 10 ? '0' : ''}${count}`}</span>
            <h2 className="section-heading__title">{t.stepTitle}</h2>
          </div>
        )}
        <div className="main-contacts__content">
          <div className="main-contacts__aside aside">
            <div className="aside__box">
              <p className="aside__heading">{t.email}</p>
              <Link className="aside__link" href="mailto:hello@mobileup.ru" onClick={() => sendYm('email')}>
                {contacts?.email ?? 'hello@mobileup.ru'}
              </Link>
            </div>
            {contacts?.offices.map(c => (
              <div key={c.cityName} className="aside__box">
                <p className="aside__heading">{c.cityName}</p>
                <Link
                  className="aside__link"
                  href={`tel:+${c.phone.replace(/[^0-9]/g, '')}`}
                  onClick={() => sendYm('phone', 'page')}
                >
                  {c.phone}
                </Link>
                <a className="aside__link">{c.address}</a>
              </div>
            ))}
          </div>
          <div className="main-contacts__text">
            <p>{t.description.greetings}</p>
            <p>
              <a className="main-contacts__link" onClick={openForm}>{t.description.formOpenText}</a>
              {t.description.firstPart}
              {locale === 'ru' && <br className="only-mobile"/>}
              {t.description.secondPart}
              <Link
                className="main-contacts__link"
                href={contacts?.telegramChatUrl ?? 'https://t.me/chat_with_mobileup'}
                target="_blank"
                rel="nofollow noopener noreferrer"
                onClick={() => sendYm('tg_chat')}
              >
                {t.description.telegramText}
              </Link>.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default memo(MainContacts)
